.project-container {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    position: relative;
}

.project-contents {
    padding: 80px auto;
    width:fit-content;
    align-self: center;
}

.project-header {
    display: flex;
    flex-direction: column;
    margin: 10px 20px 90px;
}

.project-title {
    display: flex;
    font-family: Varela, sans-serif;
    font-size: 50px;
    font-weight: 400;
    line-height: 64px;
    margin: 0;
}

.project-description {
    display: flex;
    font-family: Montserrat, sans-serif;
    width: 100%;
    font-size: 16px;
    line-height: 28px;
    color: black;
    opacity: 0.6
}

.project-subheader-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 20px;
    margin-bottom: 80px;
}

.project-overview-container {
    margin: 10px 20px;
    flex: 50%;
    max-width: 451px;
    width: fit-content;
}

.project-overview-title {
    display: flex;
    font-family: Montserrat, sans-serif;
    font-size: 22px;
    line-height: 28px;
    font-weight: 400;
}

.project-overview-contents {
    display: inline;
    font-family: Montserrat, sans-serif;
    font-size: 16px;
    line-height: 28px;
    font-weight: 400;
    color: black;
    opacity: 0.6;
}

.my-contributions-container {
    margin: 10px 20px;
    max-width: 451px;
    width: fit-content;
}

.my-contributions-title {
    display: flex;
    font-family: Montserrat, sans-serif;
    font-size: 22px;
    line-height: 28px;
    font-weight: 400;
}

.my-contributions-contents {
    display: inline;
    font-family: Montserrat, sans-serif;
    font-size: 16px;
    line-height: 28px;
    font-weight: 400;
    color: black;
    opacity: 0.6;
}

.rebrand-changes-container {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    margin: 10px 20px 100px;
}

.rebrand-changes-title {
    display: flex;
    font-family: Montserrat, sans-serif;
    font-size: 22px;
    line-height: 28px;
    font-weight: 400;
    width: 100%;
    white-space: break-word;
}

.rebrand-change-images {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.rebrand-logo-change {
    width: 300px;
    height: auto;
}

.rebrand-color-change {
    width: 300px;
    height: auto;
}

.rebrand-typography-change {
    width: 300px;
    height: auto;
}

.rebrand-screenshots-title-container {
    display: flex;
    width: fit-content;
    background-color: black;
    border-radius: 10px;
    align-items: center;
    align-self: center;
}

.rebrand-screenshots-title {
    display: flex;
    font-family: Montserrat, sans-serif;
    font-size: 22px;
    line-height: 28px;
    font-weight: 400;
    margin: 20px;
    color: white;
    text-decoration: none !important;
}

.rebrand-screenshots-container {
    display: flex;
    flex-direction: column;
    margin: 10px auto 80px;
    background-color: #f6e1fc;
    width: 100%;
    padding: 50px 0;
    flex: 1 1 100%;
    align-items: center;
}

.rebrand-screenshots {
    display: flex;
    flex-direction: column;
    margin: 50px auto 80px;
    gap: 20px;
    width: 100%;
    justify-content: center;
}

.screenshot-container {
    display: flex;
    flex-direction: column;
    margin: 0 auto 80px;
    gap: 20px;
    width: 100%;
    height: 100%;
    align-self: center;
    align-items: center;
    justify-content: center;
}

.screenshot-title-container {
    display: flex;
    width: fit-content;
    background-color: #798fa8;
    opacity: 0.8;
    border-radius: 10px;
}

.screenshot-title {
    display: flex;
    font-family: Montserrat, sans-serif;
    font-size: 18px;
    line-height: 28px;
    font-weight: 400;
    color: white;
    margin: 10px;
    width: 100%;
}

.screenshot-photo {
    display: flex;
    width: 100%;
    height: 100%;
}

.screenshots-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, calc((100% - 60px) / 2));
    grid-gap: 20px;
    width: 100%;
    justify-content: center;
    padding: 0 20px;
}

@media only screen and (max-width: 768px) { 
    .screenshots-grid {
        grid-template-columns: 1fr;
    }
}