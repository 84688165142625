.footer-container {
    padding: 50px 30px;
    background-color: #edede9;
    margin: auto 20px 20px;
    display: flex;
    flex-direction: column;
    bottom: 20px;
}

.social-media-item {
    padding: 0 5px;
}

.social-media-menu {
    width: fit-content;
    margin: auto;
}

.social-media-menu:hover a {
    opacity: 0.5; 
}

.social-media-menu a:hover {
    opacity: 1;
}

.footer-caption-container {
    margin: 25px auto 0;
    width: fit-content;
}

.footer-caption {
    text-align: center;
    font-family: Didact Gothic, sans-serif;
    font-size: 12px;
    font-weight: 400;
    color: #8a817c;
    line-height: 1.5em;
    letter-spacing: .1em;
}

.homepage-project-link-container {
    text-decoration: none;
}