.project-container {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    position: relative;
}

.project-contents {
    display: flex;
    flex-direction: column;
    margin: 80px 0;
    width: 100%;
    justify-content: center;
    align-items: center;
}

.project-header {
    display: flex;
    flex-direction: column;
    margin: 10px 20px 90px;
    width: fit-content;
    align-items: center;
}

.project-title {
    display: flex;
    font-family: Varela, sans-serif;
    font-size: 50px;
    font-weight: 400;
    line-height: 64px;
    text-align: center;
    margin-bottom: 20px;
}

.project-description {
    display: flex;
    font-family: Montserrat, sans-serif;
    width: 100%;
    font-size: 16px;
    line-height: 28px;
    color: black;
    opacity: 0.6;
    text-align: center;
}

.project-subheader-container {
    display: flex;
    flex-direction: row;
    gap: 20px;
    margin-bottom: 80px;
    justify-content: center;
}

.project-overview-container {
    width: 100%;
}

.project-overview-title {
    display: flex;
    font-family: Montserrat, sans-serif;
    font-size: 22px;
    line-height: 28px;
    font-weight: 400;
}

.project-overview-contents {
    display: inline;
    font-family: Montserrat, sans-serif;
    font-size: 16px;
    line-height: 28px;
    font-weight: 400;
    color: black;
    opacity: 0.6;
}

.my-contributions-container {
    width: 100%;
}

.my-contributions-title {
    display: flex;
    font-family: Montserrat, sans-serif;
    font-size: 22px;
    line-height: 28px;
    font-weight: 400;
}

.my-contributions-contents {
    display: inline;
    font-family: Montserrat, sans-serif;
    font-size: 16px;
    line-height: 28px;
    font-weight: 400;
    color: black;
    opacity: 0.6;
}

.split-capture-video-container {
    padding: 0 20px;
    width: 100%;
    max-width: 1000px;
}

.split-capture-video {
    width: 100%;
    height: 100%;
}