.password-prompt-container {
    height: 100%;
    display: flex;
    margin-bottom: 150px;
    align-items: center;
    justify-content: center;
}

.password-prompt-form {
    display: flex;
    flex-direction: column;
}

.password-prompt-label {
    font-family: Montserrat, sans-serif;
    font-size: 18px;
    font-weight: 400;
    line-height: 25px;
    color: #777;
    margin-bottom: 5px;
}

.password-prompt-input {
    width: 200px;
    height: 25px;
    &:focus {
        border-color: #777;
    }
}

.password-prompt-button {
    width: fit-content;
    background-color: #e8ebed;
    color: #777;
    font-size: 18px;
    font-weight: 400;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    margin-top: 10px;
    margin: 10px auto 0;
    padding: 5px 10px
}