.resume-page-container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    position: relative;
}

.resume-page-contents {
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    padding: 0 5% 30px;
}

.resume-image {
    width: 100%;
    height: 100%;
    max-width: 1055px;
}

a:-webkit-any-link {
    text-decoration: none;
}

.download-button {
    display: flex;
    background-color: #7299ff;
    padding: 10px 15px;
    width: fit-content;
    border-radius: 10px;
    margin-bottom: 30px;
    align-self: flex-end;
}

.download-text {
    display: flex;
    font-family: Montserrat, sans-serif;
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
    color: white;
    white-space: nowrap;
}
