/* html, body, body>div {
    height: 100%
} */

@font-face {
    font-family: 'Varela';
    src: local('Varela'), url(/static/media/Varela-Regular.24ba6c18.ttf) format('truetype');
  }

  @font-face {
    font-family: 'Montserrat';
    font-weight: 400;
    src: local('Montserrat'), url(/static/media/Montserrat-Regular.34de1239.ttf) format('truetype');
  }

  @font-face {
    font-family: 'Montserrat';
    font-weight: 300;
    src: local('Montserrat'), url(/static/media/Montserrat-Light.a17f43cc.ttf) format('truetype');
  }

  @font-face {
    font-family: 'Montserrat';
    font-weight: 500;
    src: local('Montserrat'), url(/static/media/Montserrat-Medium.aca6287f.ttf) format('truetype');
  }

  @font-face {
    font-family: 'Montserrat';
    font-weight: 600;
    src: local('Montserrat'), url(/static/media/Montserrat-SemiBold.7ffeec2b.ttf) format('truetype');
  }

  @font-face {
    font-family: 'Montserrat';
    font-weight: 700;
    src: local('Montserrat'), url(/static/media/Montserrat-Bold.d14ad103.ttf) format('truetype');
  }

  @font-face {
    font-family: 'Montserrat';
    font-weight: 800;
    src: local('Montserrat'), url(/static/media/Montserrat-ExtraBold.dc2f156b.ttf) format('truetype');
  }

  @font-face {
    font-family: 'Montserrat';
    font-weight: 900;
    src: local('Montserrat'), url(/static/media/Montserrat-Black.55e37a35.ttf) format('truetype');
  }

  @font-face {
    font-family: 'Didact Gothic';
    src: local('DidactGothic'), url(/static/media/DidactGothic-Regular.a9bf8ef6.ttf) format('truetype');
  }

.footer-container {
    padding: 50px 30px;
    background-color: #edede9;
    margin: auto 20px 20px;
    display: flex;
    flex-direction: column;
    bottom: 20px;
}

.social-media-item {
    padding: 0 5px;
}

.social-media-menu {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    margin: auto;
}

.social-media-menu:hover a {
    opacity: 0.5; 
}

.social-media-menu a:hover {
    opacity: 1;
}

.footer-caption-container {
    margin: 25px auto 0;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
}

.footer-caption {
    text-align: center;
    font-family: Didact Gothic, sans-serif;
    font-size: 12px;
    font-weight: 400;
    color: #8a817c;
    line-height: 1.5em;
    letter-spacing: .1em;
}

.homepage-project-link-container {
    text-decoration: none;
}
.navbar {
    display: flex;
    flex-direction: row;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
}

.navbar-and-menu {
    padding: 30px;
    display: flex;
    flex-direction: row;
    width: 100%;
}

.nav-menu {
    display: flex;
    margin-left: auto;
    list-style-type: none;
}

.nav-item {
    font-family: Montserrat, sans-serif;
    font-size: 13px;
    font-weight: 500;
    line-height: 20px;
    color: #222222;
    padding: 9px 20px;
    opacity: 0.6;
    text-decoration: none;
    margin-right: auto;
}

.nav-item:hover {
    color: #32343a;
    opacity: 0.9;
}

.nav-item--selected {
    font-weight: 600;
    line-height: 20px;
    color: #1a1b1f;
    opacity: 1;
}

.hamburger {
    display: none;
}

.bar {
    display: block;
    width: 25px;
    height: 3px;
    margin: 5px auto;
    transition: all 0.3s ease-in-out;
    background-color: #101010;
}

@media only screen and (max-width: 768px) { 
    .icon-and-hamburger {
        padding: 30px;
        display: flex;
        flex-direction: row;
    }

    .navbar-and-menu {
        padding: 0;
        display: flex;
        flex-direction: column;
        width: 100%;
    }

    .nav-menu {
        position: fixed;
        top: 120px;
        left: 100%;
        flex-direction: column;
        background-color: #f2efef;
        width: 100%;
        height: 100%;
        transition: 0.3s;
        box-shadow:
            0 10px 27px rgba(0, 0, 0, 0.05);
        margin: auto;
        align-items: center;
        padding: 20px 0;
    }

    .nav-menu.active {
        left: 0;
    }

    .nav-item {
        height: -webkit-fit-content;
        height: -moz-fit-content;
        height: fit-content;
        padding: 0px;
        font-size: 20px;
        font-weight: 500;
        line-height: 40px;
    }

    .nav-item > img {
        align-self: center;
    }

    .hamburger {
        display: block;
        cursor: pointer;
        align-self: center;
    }

    .hamburger.active .bar:nth-child(2) {
        opacity: 0;
    }

    .hamburger.active .bar:nth-child(1) {
        transform: translateY(8px) rotate(45deg);
    }

    .hamburger.active .bar:nth-child(3) {
        transform: translateY(-8px) rotate(-45deg);
    }
}
.about-page-container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    position: relative;
}

.about-page-description {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    max-width: 426px;
    display: flex;
    white-space: pre-line;
    padding: 0 20px;
    margin: 0 auto 50px;
    align-self: flex-start;
}

.about-page-contents {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    grid-gap: 0 10px;
    gap: 0 10px;
    margin: 0 auto 50px;
    align-items: baseline;
}

.about-page-text--hey-there--container {
    margin-bottom: 11px;
}

.about-page-text--hey-there {
    font-family: Montserrat, sans-serif;
    font-size: 20px;
    font-weight: 500;
    color: #6c757d;
    letter-spacing: 1px;
    line-height: 20px;
}

.about-page-text--im-melody--container {
    margin-bottom: 20px;
}

.about-page-text--im-melody {
    font-family: Montserrat, sans-serif;
    font-size: 50px;
    font-weight: 500;
    color: #1a1b1f;
    line-height: 50px;
    margin-bottom: 50px;
}

.about-page-text--container {
    margin-bottom: 70px;
}

.about-page-text {
    font-family: Montserrat, sans-serif;
    font-size: 16px;
    font-weight: 300;
    color: #454246;
    line-height: 28px;
}

.about-page-text--my-experience--container {
    margin-bottom: 10px;
}

.about-page-text--my-experience {
    font-family: Montserrat, sans-serif;
    font-size: 24px;
    font-weight: 500;
    color: #1a1b1f;
    line-height: 38px;
}

.about-page-text--experience-content {
    font-family: Montserrat, sans-serif;
    font-size: 16px;
    color: #1a1b1f;
    line-height: 32px;
}

.about-page-photo-container {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    display: flex;
    padding: 0 20px;
    margin: 0 auto 50px;;
}

.about-page-photo {
    width: 100%;
    height: auto;
    max-width: 423px;
    max-height: 635px;
    display: flex;
}

.homepage-container {
    height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    position: relative;
}

.homepage-contents {
    height: 100%;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    margin: 0 auto 100px;
    align-items: center;
    justify-content: center;
}

.homepage-header {
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    align-self: flex-start;
    display: flex;
    flex-direction: column;
    flex: wrap 1;
    max-width: 1030px;
    margin: 0 auto;
    padding: 0 20px;
}

.homepage-title {
    display: flex;
    font-family: Varela, sans-serif;
    font-size: 50px;
    font-weight: 700;
    line-height: 40px;
} 

.homepage-description {
    display: inline-block;
    width: 100%;
    margin-top: 10px;
}

.homepage-description--text {
    font-family: Varela, sans-serif;
    font-size: 30px;
    font-weight: 500;
    color: #525252;
    line-height: 40px;
}

.homepage-description--nerdwallet {
    font-family: Varela, sans-serif;
    font-size: 30px;
    font-weight: 700;
    color: #008254;
    line-height: 40px;
}

.homepage-description--music-tech {
    font-family: Montserrat, sans-serif;
    font-size: 30px;
    font-weight: 700;
    color: #525252;
    line-height: 40px;
}

.social-media-menu {
    align-self: flex-end;
    padding-bottom: 50px;
}
.password-prompt-container {
    height: 100%;
    display: flex;
    margin-bottom: 150px;
    align-items: center;
    justify-content: center;
}

.password-prompt-form {
    display: flex;
    flex-direction: column;
}

.password-prompt-label {
    font-family: Montserrat, sans-serif;
    font-size: 18px;
    font-weight: 400;
    line-height: 25px;
    color: #777;
    margin-bottom: 5px;
}

.password-prompt-input {
    width: 200px;
    height: 25px;
    &:focus {
        border-color: #777;
    }
}

.password-prompt-button {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    background-color: #e8ebed;
    color: #777;
    font-size: 18px;
    font-weight: 400;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    margin-top: 10px;
    margin: 10px auto 0;
    padding: 5px 10px
}
.work-container {
    height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    position: relative;
}

.homepage-project-gallery {
    display: grid;
    grid-template-columns: repeat(auto-fill, calc((100% - 50px) / 2));
    grid-gap: 50px;
    width: 100%;
    justify-content: center;
    margin: 50px 0;
}

@media only screen and (max-width: 768px) {
    .homepage-project-gallery {
        grid-template-columns: 1fr
    }
}

.homepage-project-text {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    margin-top: 40px;
}

.homepage-project-title {
    font-family: Montserrat, sans-serif;
    font-size: 20px;
    color: #1a1b1f;
    line-height: 34px;
    width: 100%;
}

.homepage-project-description {
    width: 100%;
    height: 20%;
    font-family: Montserrat, sans-serif;
    font-size: 16px;
    color: #adb5bd;
    line-height: 28px;
}

.homepage-project-link-container {
    display: flex;
    padding: 10px;
    width: 100%;
    height: 100%;
    justify-content: center;
    text-decoration: none;
}

.homepage-project-container:hover {
    cursor: pointer;
}

.homepage-project-photo {
    display: flex;
    max-width: 700px;
    width: 100%;
    height: auto;
    box-shadow: 2px 2px 7px -2px #000;
    align-self: center;
    margin: 0 auto;
}

.homepage-project-photo:hover {
    opacity: 0.7;
    transition: opacity 0.1s ease-in-out;
}
.project-container {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    position: relative;
}

.project-contents {
    padding: 80px auto;
    width:-webkit-fit-content;
    width:-moz-fit-content;
    width:fit-content;
    align-self: center;
}

.project-header {
    display: flex;
    flex-direction: column;
    margin: 10px 20px 90px;
}

.project-title {
    display: flex;
    font-family: Varela, sans-serif;
    font-size: 50px;
    font-weight: 400;
    line-height: 64px;
    margin: 0;
}

.project-description {
    display: flex;
    font-family: Montserrat, sans-serif;
    width: 100%;
    font-size: 16px;
    line-height: 28px;
    color: black;
    opacity: 0.6
}

.project-subheader-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    grid-gap: 20px;
    gap: 20px;
    margin-bottom: 80px;
}

.project-overview-container {
    margin: 10px 20px;
    flex: 50% 1;
    max-width: 451px;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
}

.project-overview-title {
    display: flex;
    font-family: Montserrat, sans-serif;
    font-size: 22px;
    line-height: 28px;
    font-weight: 400;
}

.project-overview-contents {
    display: inline;
    font-family: Montserrat, sans-serif;
    font-size: 16px;
    line-height: 28px;
    font-weight: 400;
    color: black;
    opacity: 0.6;
}

.my-contributions-container {
    margin: 10px 20px;
    max-width: 451px;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
}

.my-contributions-title {
    display: flex;
    font-family: Montserrat, sans-serif;
    font-size: 22px;
    line-height: 28px;
    font-weight: 400;
}

.my-contributions-contents {
    display: inline;
    font-family: Montserrat, sans-serif;
    font-size: 16px;
    line-height: 28px;
    font-weight: 400;
    color: black;
    opacity: 0.6;
}

.rebrand-changes-container {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    margin: 10px 20px 100px;
}

.rebrand-changes-title {
    display: flex;
    font-family: Montserrat, sans-serif;
    font-size: 22px;
    line-height: 28px;
    font-weight: 400;
    width: 100%;
    white-space: break-word;
}

.rebrand-change-images {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.rebrand-logo-change {
    width: 300px;
    height: auto;
}

.rebrand-color-change {
    width: 300px;
    height: auto;
}

.rebrand-typography-change {
    width: 300px;
    height: auto;
}

.rebrand-screenshots-title-container {
    display: flex;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    background-color: black;
    border-radius: 10px;
    align-items: center;
    align-self: center;
}

.rebrand-screenshots-title {
    display: flex;
    font-family: Montserrat, sans-serif;
    font-size: 22px;
    line-height: 28px;
    font-weight: 400;
    margin: 20px;
    color: white;
    text-decoration: none !important;
}

.rebrand-screenshots-container {
    display: flex;
    flex-direction: column;
    margin: 10px auto 80px;
    background-color: #f6e1fc;
    width: 100%;
    padding: 50px 0;
    flex: 1 1 100%;
    align-items: center;
}

.rebrand-screenshots {
    display: flex;
    flex-direction: column;
    margin: 50px auto 80px;
    grid-gap: 20px;
    gap: 20px;
    width: 100%;
    justify-content: center;
}

.screenshot-container {
    display: flex;
    flex-direction: column;
    margin: 0 auto 80px;
    grid-gap: 20px;
    gap: 20px;
    width: 100%;
    height: 100%;
    align-self: center;
    align-items: center;
    justify-content: center;
}

.screenshot-title-container {
    display: flex;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    background-color: #798fa8;
    opacity: 0.8;
    border-radius: 10px;
}

.screenshot-title {
    display: flex;
    font-family: Montserrat, sans-serif;
    font-size: 18px;
    line-height: 28px;
    font-weight: 400;
    color: white;
    margin: 10px;
    width: 100%;
}

.screenshot-photo {
    display: flex;
    width: 100%;
    height: 100%;
}

.screenshots-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, calc((100% - 60px) / 2));
    grid-gap: 20px;
    width: 100%;
    justify-content: center;
    padding: 0 20px;
}

@media only screen and (max-width: 768px) { 
    .screenshots-grid {
        grid-template-columns: 1fr;
    }
}
.project-container {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    position: relative;
}

.project-contents {
    display: flex;
    flex-direction: column;
    margin: 80px 0;
    width: 100%;
    justify-content: center;
    align-items: center;
}

.project-header {
    display: flex;
    flex-direction: column;
    margin: 10px 20px 90px;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    align-items: center;
}

.project-title {
    display: flex;
    font-family: Varela, sans-serif;
    font-size: 50px;
    font-weight: 400;
    line-height: 64px;
    text-align: center;
    margin-bottom: 20px;
}

.project-description {
    display: flex;
    font-family: Montserrat, sans-serif;
    width: 100%;
    font-size: 16px;
    line-height: 28px;
    color: black;
    opacity: 0.6;
    text-align: center;
}

.project-subheader-container {
    display: flex;
    flex-direction: row;
    grid-gap: 20px;
    gap: 20px;
    margin-bottom: 80px;
    justify-content: center;
}

.project-overview-container {
    width: 100%;
}

.project-overview-title {
    display: flex;
    font-family: Montserrat, sans-serif;
    font-size: 22px;
    line-height: 28px;
    font-weight: 400;
}

.project-overview-contents {
    display: inline;
    font-family: Montserrat, sans-serif;
    font-size: 16px;
    line-height: 28px;
    font-weight: 400;
    color: black;
    opacity: 0.6;
}

.my-contributions-container {
    width: 100%;
}

.my-contributions-title {
    display: flex;
    font-family: Montserrat, sans-serif;
    font-size: 22px;
    line-height: 28px;
    font-weight: 400;
}

.my-contributions-contents {
    display: inline;
    font-family: Montserrat, sans-serif;
    font-size: 16px;
    line-height: 28px;
    font-weight: 400;
    color: black;
    opacity: 0.6;
}

.split-capture-video-container {
    padding: 0 20px;
    width: 100%;
    max-width: 1000px;
}

.split-capture-video {
    width: 100%;
    height: 100%;
}
.resume-page-container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    position: relative;
}

.resume-page-contents {
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    padding: 0 5% 30px;
}

.resume-image {
    width: 100%;
    height: 100%;
    max-width: 1055px;
}

a:-webkit-any-link {
    text-decoration: none;
}

.download-button {
    display: flex;
    background-color: #7299ff;
    padding: 10px 15px;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    border-radius: 10px;
    margin-bottom: 30px;
    align-self: flex-end;
}

.download-text {
    display: flex;
    font-family: Montserrat, sans-serif;
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
    color: white;
    white-space: nowrap;
}

.project-container {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    position: relative;
}

.project-contents {
    display: flex;
    flex-direction: column;
    margin: 80px 0;
    width: 100%;
}

.project-header {
    display: flex;
    flex-direction: column;
    margin: 10px 20px 90px;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    align-items: center;
}

.project-title {
    display: flex;
    font-family: Varela, sans-serif;
    font-size: 50px;
    font-weight: 400;
    line-height: 64px;
    text-align: center;
    margin-bottom: 20px;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
}

.project-description {
    display: flex;
    font-family: Montserrat, sans-serif;
    width: 100%;
    font-size: 16px;
    line-height: 28px;
    color: black;
    opacity: 0.6;
    text-align: center;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
}

.project-subheader-container {
    display: flex;
    flex-direction: row;
    grid-gap: 20px;
    gap: 20px;
    margin-bottom: 80px;
}

.project-overview-container {
    width: 100%;
}

.project-overview-title {
    display: flex;
    font-family: Montserrat, sans-serif;
    font-size: 22px;
    line-height: 28px;
    font-weight: 400;
}

.project-overview-contents {
    display: inline;
    font-family: Montserrat, sans-serif;
    font-size: 16px;
    line-height: 28px;
    font-weight: 400;
    color: black;
    opacity: 0.6;
}

.my-contributions-container {
    width: 100%;
}

.my-contributions-title {
    display: flex;
    font-family: Montserrat, sans-serif;
    font-size: 22px;
    line-height: 28px;
    font-weight: 400;
}

.my-contributions-contents {
    display: inline;
    font-family: Montserrat, sans-serif;
    font-size: 16px;
    line-height: 28px;
    font-weight: 400;
    color: black;
    opacity: 0.6;
}

.account-creation-video-container {
    padding: 0 20px;
    width: 100%;
    max-width: 1000px;
}

.account-creation-video {
    width: 100%;
    height: 100%;
}

body {
  font-family: "Ubuntu", sans-serif;
  font-size: 18px;
  color: #010000;
  font-weight: 400;
  font-style: normal;
}

.container-fluid {
  line-height: 24px;
}

header {
  position: relative;
  display: inline-block;
  width: 100%;
  background: #ffffff;
  color: #ffffff;
  height: 60px;
  border-bottom-style: solid;
  border-bottom-width: thin;
}
header nav,
header nav ul,
header nav ul li {
  white-space: nowrap;
  overflow: hidden;
}
header nav {
  position: absolute;
  top: 50%;
  right: 15px;
  margin-top: 10px;
  transform: translateY(-50%);
}
header nav ul li {
  display: inline-block;
  margin-right: 10px;
  padding: 10px;
}
header nav ul li.last {
  margin: 0;
}
header nav ul li a {
  font-size: 20px;
  font-family: "Ubuntu", sans-serif;
  line-height: normal;
  color: #010000;
  text-decoration: none;
  transition: 0.01s ease-in-out;
  cursor: pointer;
}
header nav ul li a:hover {
  text-decoration: none;
  color: #0d98ba;
  transition: 0.01s ease-in-out;
}

body {
  background: #ffffff;
  overflow-x: hidden;
}
body p {
  width: 60%;
  white-space: nowrap;
}
body p1 {
  font-family: "Ubuntu", sans-serif;
  width: 50%;
  font-size: 50px;
  color: #010000;
}
body p1 a {
  font-size: 40px;
  color: #010000;
  font-family: "Ubuntu", sans-serif;
  text-decoration: none;
}
body p1 a:hover {
  text-decoration: none;
  color: #0d98ba;
  transition: 0.01s ease-in-out;
}
body p2 {
  display: block;
  font-family: "Ubuntu", sans-serif;
  font-weight: 400;
  color: #585555;
  font-size: 20px;
  margin-top: 30px;
  height: 150px;
  line-height: 35px;
  position: absolute;
}
body p2 u1 {
  color: #FF3300;
}
body p2 u1:hover {
  text-decoration: underline;
  color: #FF9966;
  transition: 0.01s ease-in-out;
}
body p2 u2 {
  color: #9933FF;
}
body p2 u2:hover {
  text-decoration: underline;
  color: #CC99FF;
  transition: 0.01s ease-in-out;
}
body p2 u3 {
  color: #3366FF;
}
body p2 u3:hover {
  text-decoration: underline;
  color: #0099FF;
  transition: 0.01s ease-in-out;
}
body p2 u4 {
  color: #228B22;
}
body p2 u4:hover {
  text-decoration: underline;
  color: #32CD32;
  transition: 0.01s ease-in-out;
}
body p3 {
  display: flex;
  margin-left: 100px;
  margin-top: 200px;
}
body p3 .mammoth {
  margin-right: 50px;
  opacity: 1;
  transition: opacity 0.25s ease-in-out;
  -moz-transition: opacity 0.25s ease-in-out;
  -webkit-transition: opacity 0.25s ease-in-out;
}
body p3 .mammoth:hover {
  opacity: 0.7;
}
body p3 .google {
  margin-right: 50px;
  opacity: 1;
  transition: opacity 0.25s ease-in-out;
  -moz-transition: opacity 0.25s ease-in-out;
  -webkit-transition: opacity 0.25s ease-in-out;
}
body p3 .google:hover {
  opacity: 0.7;
}
body p3 .blueprint {
  opacity: 1;
  transition: opacity 0.25s ease-in-out;
  -moz-transition: opacity 0.25s ease-in-out;
  -webkit-transition: opacity 0.25s ease-in-out;
}
body p3 .blueprint:hover {
  opacity: 0.7;
}
body p4 {
  display: block;
  font-weight: 400;
  font-family: "Heebo", sans-serif;
  font-weight: 400;
  font-size: 20px;
}
body p5 {
  display: block;
  margin-top: 1px;
  font-family: "Heebo", sans-serif;
  font-weight: 400;
  font-size: 18px;
}
body p6 {
  display: block;
  margin-top: 1px;
  font-family: "Heebo", sans-serif;
  font-weight: 400;
  color: #585555;
  font-size: 15px;
}
body .captions ul,
body .captions ul li {
  margin-top: 10px;
  font-size: 20px;
  font-family: "Ubuntu", sans-serif;
  white-space: nowrap;
  overflow: hidden;
  display: inline-block;
  margin-left: 65px;
  padding-right: 180px;
}
body .captions ul .blueprint_cap,
body .captions ul li .blueprint_cap {
  margin-left: 100px;
}
body .card {
  background-color: #F6F6F6;
  border: none;
  border-radius: 25px;
}
body .card:hover {
  background-color: #F0F0F0;
}
body .card-img-top {
  border-radius: 20px;
}
body .down-arrow {
  display: block;
  width: 40px;
  height: 40px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: auto;
  margin-top: 20px;
}
body .bounce {
  animation: bounce 2s infinite;
  display: block;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  position: absolute;
  margin-bottom: 0px;
}
@keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-30px);
  }
  60% {
    transform: translateY(-15px);
  }
}

/*# sourceMappingURL=default.min.css.map */

.project-container {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    position: relative;
}

.project-contents {
    display: flex;
    flex-direction: column;
    margin: 80px 0;
    width: 100%;
}

.project-header {
    display: flex;
    flex-direction: column;
    margin: 10px 20px 90px;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    align-items: center;
}

.project-title {
    display: flex;
    font-family: Varela, sans-serif;
    font-size: 50px;
    font-weight: 400;
    line-height: 64px;
    text-align: center;
    margin-bottom: 20px;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
}

.project-description {
    display: flex;
    font-family: Montserrat, sans-serif;
    width: 100%;
    font-size: 16px;
    line-height: 28px;
    color: black;
    opacity: 0.6;
    text-align: center;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
}

.project-subheader-container {
    display: flex;
    flex-direction: row;
    grid-gap: 20px;
    gap: 20px;
    margin-bottom: 80px;
}

.project-overview-container {
    width: 100%;
}

.project-overview-title {
    display: flex;
    font-family: Montserrat, sans-serif;
    font-size: 22px;
    line-height: 28px;
    font-weight: 400;
}

.project-overview-contents {
    display: inline;
    font-family: Montserrat, sans-serif;
    font-size: 16px;
    line-height: 28px;
    font-weight: 400;
    color: black;
    opacity: 0.6;
}

.my-contributions-container {
    width: 100%;
}

.my-contributions-title {
    display: flex;
    font-family: Montserrat, sans-serif;
    font-size: 22px;
    line-height: 28px;
    font-weight: 400;
}

.my-contributions-contents {
    display: inline;
    font-family: Montserrat, sans-serif;
    font-size: 16px;
    line-height: 28px;
    font-weight: 400;
    color: black;
    opacity: 0.6;
}

.onboarding-funnel-image-container {
    display: flex;
    flex-direction: column;
    padding: 0 20px;
    width: 100%;
    max-width: 1000px;
}

.unlock-integration-container {
    display: flex;
    flex-direction: row;
    margin-top: 50px;
}

.onboarding-funnel-status-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 1000px;
}

.activation-video-image {
    width: 100%;
    height: 100%;
}

.onboarding-funnel-status-image {
    width: 100%;
    height: auto;
}

.blur-api-image {
    width: 50%;
    height: auto;
}
.project-container {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    position: relative;
}

.project-contents {
    display: flex;
    flex-direction: column;
    margin: 80px 0;
    width: 100%;
}

.project-header {
    display: flex;
    flex-direction: column;
    margin: 10px 20px 90px;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    align-items: center;
}

.project-title {
    display: flex;
    font-family: Varela, sans-serif;
    font-size: 50px;
    font-weight: 400;
    line-height: 64px;
    text-align: center;
    margin-bottom: 20px;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
}

.project-description {
    display: flex;
    font-family: Montserrat, sans-serif;
    width: 100%;
    font-size: 16px;
    line-height: 28px;
    color: black;
    opacity: 0.6;
    text-align: center;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
}

.project-subheader-container {
    display: flex;
    flex-direction: row;
    grid-gap: 20px;
    gap: 20px;
    margin-bottom: 80px;
}

.project-overview-container {
    width: 100%;
}

.project-overview-title {
    display: flex;
    font-family: Montserrat, sans-serif;
    font-size: 22px;
    line-height: 28px;
    font-weight: 400;
}

.project-overview-contents {
    display: inline;
    font-family: Montserrat, sans-serif;
    font-size: 16px;
    line-height: 28px;
    font-weight: 400;
    color: black;
    opacity: 0.6;
}

.my-contributions-container {
    width: 100%;
}

.my-contributions-title {
    display: flex;
    font-family: Montserrat, sans-serif;
    font-size: 22px;
    line-height: 28px;
    font-weight: 400;
}

.my-contributions-contents {
    display: inline;
    font-family: Montserrat, sans-serif;
    font-size: 16px;
    line-height: 28px;
    font-weight: 400;
    color: black;
    opacity: 0.6;
}

.mobile-responsive-video-container {
    display: flex;
    flex-direction: column;
    padding: 0 20px;
    width: 100%;
    height: auto;
    max-width: 1000px;
    grid-gap: 50px;
    gap: 50px;
}

.unlock-integration-container {
    display: flex;
    flex-direction: row;
    margin-top: 50px;
}

.onboarding-funnel-status-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 1000px;
}

.activation-video-image {
    width: 100%;
    height: 100%;
}

.onboarding-funnel-status-image {
    width: 100%;
    height: auto;
}

.blur-api-image {
    width: 50%;
    height: auto;
}

.mobile-screens {
    display: flex;
    flex-direction: row;
    grid-gap: 20px;
    gap: 20px;
    justify-content: center;
    flex-wrap: wrap;
}

.mobile-video {
    width: 40%;
    min-width: 300px;
    height: auto;
}
