.work-container {
    height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    position: relative;
}

.homepage-project-gallery {
    display: grid;
    grid-template-columns: repeat(auto-fill, calc((100% - 50px) / 2));
    grid-gap: 50px;
    width: 100%;
    justify-content: center;
    margin: 50px 0;
}

@media only screen and (max-width: 768px) {
    .homepage-project-gallery {
        grid-template-columns: 1fr
    }
}

.homepage-project-text {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    margin-top: 40px;
}

.homepage-project-title {
    font-family: Montserrat, sans-serif;
    font-size: 20px;
    color: #1a1b1f;
    line-height: 34px;
    width: 100%;
}

.homepage-project-description {
    width: 100%;
    height: 20%;
    font-family: Montserrat, sans-serif;
    font-size: 16px;
    color: #adb5bd;
    line-height: 28px;
}

.homepage-project-link-container {
    display: flex;
    padding: 10px;
    width: 100%;
    height: 100%;
    justify-content: center;
    text-decoration: none;
}

.homepage-project-container:hover {
    cursor: pointer;
}

.homepage-project-photo {
    display: flex;
    max-width: 700px;
    width: 100%;
    height: auto;
    box-shadow: 2px 2px 7px -2px #000;
    align-self: center;
    margin: 0 auto;
}

.homepage-project-photo:hover {
    opacity: 0.7;
    transition: opacity 0.1s ease-in-out;
}