/* html, body, body>div {
    height: 100%
} */

@font-face {
    font-family: 'Varela';
    src: local('Varela'), url(./fonts/Varela/Varela-Regular.ttf) format('truetype');
  }

  @font-face {
    font-family: 'Montserrat';
    font-weight: 400;
    src: local('Montserrat'), url(./fonts/Montserrat/static/Montserrat-Regular.ttf) format('truetype');
  }

  @font-face {
    font-family: 'Montserrat';
    font-weight: 300;
    src: local('Montserrat'), url(./fonts/Montserrat/static/Montserrat-Light.ttf) format('truetype');
  }

  @font-face {
    font-family: 'Montserrat';
    font-weight: 500;
    src: local('Montserrat'), url(./fonts/Montserrat/static/Montserrat-Medium.ttf) format('truetype');
  }

  @font-face {
    font-family: 'Montserrat';
    font-weight: 600;
    src: local('Montserrat'), url(./fonts/Montserrat/static/Montserrat-SemiBold.ttf) format('truetype');
  }

  @font-face {
    font-family: 'Montserrat';
    font-weight: 700;
    src: local('Montserrat'), url(./fonts/Montserrat/static/Montserrat-Bold.ttf) format('truetype');
  }

  @font-face {
    font-family: 'Montserrat';
    font-weight: 800;
    src: local('Montserrat'), url(./fonts/Montserrat/static/Montserrat-ExtraBold.ttf) format('truetype');
  }

  @font-face {
    font-family: 'Montserrat';
    font-weight: 900;
    src: local('Montserrat'), url(./fonts/Montserrat/static/Montserrat-Black.ttf) format('truetype');
  }

  @font-face {
    font-family: 'Didact Gothic';
    src: local('DidactGothic'), url(./fonts/Didact_Gothic/DidactGothic-Regular.ttf) format('truetype');
  }
