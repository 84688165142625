.homepage-container {
    height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    position: relative;
}

.homepage-contents {
    height: 100%;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    margin: 0 auto 100px;
    align-items: center;
    justify-content: center;
}

.homepage-header {
    height: fit-content;
    align-self: flex-start;
    display: flex;
    flex-direction: column;
    flex: wrap;
    max-width: 1030px;
    margin: 0 auto;
    padding: 0 20px;
}

.homepage-title {
    display: flex;
    font-family: Varela, sans-serif;
    font-size: 50px;
    font-weight: 700;
    line-height: 40px;
} 

.homepage-description {
    display: inline-block;
    width: 100%;
    margin-top: 10px;
}

.homepage-description--text {
    font-family: Varela, sans-serif;
    font-size: 30px;
    font-weight: 500;
    color: #525252;
    line-height: 40px;
}

.homepage-description--nerdwallet {
    font-family: Varela, sans-serif;
    font-size: 30px;
    font-weight: 700;
    color: #008254;
    line-height: 40px;
}

.homepage-description--music-tech {
    font-family: Montserrat, sans-serif;
    font-size: 30px;
    font-weight: 700;
    color: #525252;
    line-height: 40px;
}

.social-media-menu {
    align-self: flex-end;
    padding-bottom: 50px;
}