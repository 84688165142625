.about-page-container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    position: relative;
}

.about-page-description {
    width: fit-content;
    max-width: 426px;
    display: flex;
    white-space: pre-line;
    padding: 0 20px;
    margin: 0 auto 50px;
    align-self: flex-start;
}

.about-page-contents {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 0 10px;
    margin: 0 auto 50px;
    align-items: baseline;
}

.about-page-text--hey-there--container {
    margin-bottom: 11px;
}

.about-page-text--hey-there {
    font-family: Montserrat, sans-serif;
    font-size: 20px;
    font-weight: 500;
    color: #6c757d;
    letter-spacing: 1px;
    line-height: 20px;
}

.about-page-text--im-melody--container {
    margin-bottom: 20px;
}

.about-page-text--im-melody {
    font-family: Montserrat, sans-serif;
    font-size: 50px;
    font-weight: 500;
    color: #1a1b1f;
    line-height: 50px;
    margin-bottom: 50px;
}

.about-page-text--container {
    margin-bottom: 70px;
}

.about-page-text {
    font-family: Montserrat, sans-serif;
    font-size: 16px;
    font-weight: 300;
    color: #454246;
    line-height: 28px;
}

.about-page-text--my-experience--container {
    margin-bottom: 10px;
}

.about-page-text--my-experience {
    font-family: Montserrat, sans-serif;
    font-size: 24px;
    font-weight: 500;
    color: #1a1b1f;
    line-height: 38px;
}

.about-page-text--experience-content {
    font-family: Montserrat, sans-serif;
    font-size: 16px;
    color: #1a1b1f;
    line-height: 32px;
}

.about-page-photo-container {
    width: fit-content;
    display: flex;
    padding: 0 20px;
    margin: 0 auto 50px;;
}

.about-page-photo {
    width: 100%;
    height: auto;
    max-width: 423px;
    max-height: 635px;
    display: flex;
}
