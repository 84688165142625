.navbar {
    display: flex;
    flex-direction: row;
    height: fit-content;
}

.navbar-and-menu {
    padding: 30px;
    display: flex;
    flex-direction: row;
    width: 100%;
}

.nav-menu {
    display: flex;
    margin-left: auto;
    list-style-type: none;
}

.nav-item {
    font-family: Montserrat, sans-serif;
    font-size: 13px;
    font-weight: 500;
    line-height: 20px;
    color: #222222;
    padding: 9px 20px;
    opacity: 0.6;
    text-decoration: none;
    margin-right: auto;
}

.nav-item:hover {
    color: #32343a;
    opacity: 0.9;
}

.nav-item--selected {
    font-weight: 600;
    line-height: 20px;
    color: #1a1b1f;
    opacity: 1;
}

.hamburger {
    display: none;
}

.bar {
    display: block;
    width: 25px;
    height: 3px;
    margin: 5px auto;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    background-color: #101010;
}

@media only screen and (max-width: 768px) { 
    .icon-and-hamburger {
        padding: 30px;
        display: flex;
        flex-direction: row;
    }

    .navbar-and-menu {
        padding: 0;
        display: flex;
        flex-direction: column;
        width: 100%;
    }

    .nav-menu {
        position: fixed;
        top: 120px;
        left: 100%;
        flex-direction: column;
        background-color: #f2efef;
        width: 100%;
        height: 100%;
        transition: 0.3s;
        box-shadow:
            0 10px 27px rgba(0, 0, 0, 0.05);
        margin: auto;
        align-items: center;
        padding: 20px 0;
    }

    .nav-menu.active {
        left: 0;
    }

    .nav-item {
        height: fit-content;
        padding: 0px;
        font-size: 20px;
        font-weight: 500;
        line-height: 40px;
    }

    .nav-item > img {
        align-self: center;
    }

    .hamburger {
        display: block;
        cursor: pointer;
        align-self: center;
    }

    .hamburger.active .bar:nth-child(2) {
        opacity: 0;
    }

    .hamburger.active .bar:nth-child(1) {
        transform: translateY(8px) rotate(45deg);
    }

    .hamburger.active .bar:nth-child(3) {
        transform: translateY(-8px) rotate(-45deg);
    }
}